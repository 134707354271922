import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { getDashboardData } from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import "./dashboard.css";

const Dashboard = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [max, setMax] = useState({
    users: 0,
    appointments: 0,
    orders: 0,
    downloads: 0,
    onlineUsers: 0,
    categoryWiseAppointments: 0,
  });

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [data, setData] = useState({});
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getDashboardData(search);
      if (apiResponse?.data?.data) {
        setData({ ...apiResponse?.data?.data });

        setMax({
          users: Math.max(
            ...apiResponse?.data?.data?.users?.analytics?.map((el) => el.total)
          ),
          appointments: Math.max(
            ...apiResponse?.data?.data?.appointments?.analytics?.map(
              (el) => el.total
            )
          ),
          orders: Math.max(
            ...apiResponse?.data?.data?.orders?.analytics?.map((el) => el.total)
          ),
          downloads: Math.max(
            ...apiResponse?.data?.data?.downloads?.analytics?.map(
              (el) => el.total
            )
          ),
          onlineUsers: Math.max(
            ...apiResponse?.data?.data?.onlineUsers?.analytics?.map(
              (el) => el.total
            )
          ),
          categoryWiseAppointments: Math.max(
            ...apiResponse?.data?.data?.categoryWiseAppointments?.analytics?.map(
              (el) => el.total
            )
          ),
        });
        stopLoadingRequest();
      } else {
        setData({});
        setMax({ users: 0, appointments: 0, orders: 0 });
        stopLoadingRequest();
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        // toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [, search]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname, search]);

  const changehandler = useCallback((event) => {
    navigate(`/dashboard?requestType=${event.target.value}`);
  }, []);

  return (
    <div className="section dashboard">
      <div className="table-header">
        <div className="input-wrapper">
          <select
            value={
              search
                ?.split("?")[1]
                ?.split("&")
                ?.filter((el) => el.includes("requestType"))[0]
                ?.split("=")[1]
                ?.split("%20")
                .join(" ") || "Today"
            }
            onChange={changehandler}
          >
            <option value="Today">Today</option>
            <option value="Last 7 days">Last 7 days</option>
            <option value="Last 30 days">Last 30 days</option>
            <option value="Last 90 days">Last 90 days</option>
            <option value="Last 1 year">Last 1 year</option>
          </select>
        </div>
      </div>
      <div className="cards-wrapper">
        <div className="wrapper">
          <div className="card">
            <h3>USERS</h3>
            <div className="chart users">
              <span>{data?.users?.total}</span>
              {data?.users?.analytics?.length > 0 && (
                <ul>
                  {data?.users?.analytics?.map((item, index) => (
                    <li key={`dashboard=${index}`}>
                      <span>{item.total}</span>
                      <p>{item.date}</p>
                      <div
                        style={{
                          height: `${(100 / max.users) * item.total}%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>ORDERS</h3>
            <div className="chart orders">
              <span>{data?.orders?.total}</span>
              {data?.orders?.analytics?.length > 0 && (
                <ul>
                  {data?.orders?.analytics?.map((item) => (
                    <li>
                      <span>{item.total}</span>
                      <p>{item.date}</p>
                      <div
                        style={{
                          height: `${(100 / max.orders) * item.total}%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>APPOINTMENTS</h3>
            <div className="chart appointments">
              <span>{data?.appointments?.total}</span>
              {data?.appointments?.analytics?.length > 0 && (
                <ul>
                  {data?.appointments?.analytics?.map((item) => (
                    <li>
                      <span>{item.total}</span>
                      <p>{item.date}</p>
                      <div
                        style={{
                          height: `${(100 / max.appointments) * item.total}%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>APP DOWNLOADS</h3>
            <div className="chart users">
              <span>{data?.downloads?.total}</span>
              {data?.downloads?.analytics?.length > 0 && (
                <ul>
                  {data?.downloads?.analytics?.map((item) => (
                    <li>
                      <span>{item.total}</span>
                      <p>{item.date}</p>
                      <div
                        style={{
                          height: `${(100 / max.downloads) * item.total}%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>ONLINE USERS</h3>
            <div className="chart orders">
              <span>{data?.onlineUsers?.total}</span>
              {data?.onlineUsers?.analytics?.length > 0 && (
                <ul>
                  {data?.onlineUsers?.analytics?.map((item) => (
                    <li>
                      <span>{item.total}</span>
                      <p>{item.date}</p>
                      <div
                        style={{
                          height: `${(100 / max.onlineUsers) * item.total}%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="card">
            <h3>CATEGORY WISE APPOINTMENTS</h3>
            <div className="chart orders">
              <span>{data?.categoryWiseAppointments?.total}</span>
              {data?.categoryWiseAppointments?.analytics?.length > 0 && (
                <ul>
                  {data?.categoryWiseAppointments?.analytics?.map((item) => (
                    <li>
                      <span>{item.total}</span>
                      <p>{item._id}</p>
                      <div
                        style={{
                          height: `${
                            (100 / max.categoryWiseAppointments) * item.total
                          }%`,
                        }}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );
export default connect(null, mapDispatchToProps)(Dashboard);
