import React, { useState, useEffect, useCallback } from "react";
import moment from 'moment';
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  getPrivacyPolicies,
  deletePrivacyPolicy,
  makePrivacyPolicyLive,
} from "../../apis";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import Pagination from "../../components/Pagination/Pagination";
import AddEdit from "./AddEdit";

const PrivacyPolicies = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState({});
  const { pathname, search } = useLocation();
  const fetch = useCallback(async () => {
    try {
      const apiResponse = await getPrivacyPolicies(search);
      if (apiResponse?.data?.data?.privacyPolicies?.length > 0) {
        setData({ ...apiResponse?.data?.data });
      } else {
        setData({});
      }
      stopLoadingRequest();
    } catch (error) {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      } else if (error?.response?.status === 403) {
        toast.error(error.response.data.message);
      }
      stopLoadingRequest();
    }
  }, [data, search, pathname]);

  useEffect(() => {
    startLoadingRequest();
    fetch();
  }, [pathname, search]);
  const activateDeactivatePrivacyPolicyHandler = useCallback(
    async (id, isActive) => {
      try {
        startLoadingRequest();
        await deletePrivacyPolicy(id);
        toast.success(
          !isActive
            ? "Privacy policy activated successfully"
            : "Privacy policy deactivated successfully"
        );
        fetch();
      } catch (error) {
        if (error?.response?.status === 401) {
          toast.error(error.response.data.message);
          logoutRequest();
        } else if (error?.response?.status === 403) {
          toast.error(error.response.data.message);
        }
        stopLoadingRequest();
      }
    },
    []
  );
  const makePrivacyPolicyLiveHandler = useCallback(async (id) => {
    try {
      startLoadingRequest();
      await makePrivacyPolicyLive(id);
      toast.success("Privacy policy is now live");
      fetch();
    } catch (error) {
      toast.error(error.response.data.message);
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message);
        logoutRequest();
      }
      stopLoadingRequest();
    }
  }, []);

  function convertUTCtoLocalTime(utcDateTime) {
    return moment.utc(utcDateTime, "YYYY-MM-DD HH:mm:ss").local().format('YYYY-MM-DD hh:mm:ss A');
}


  return (
    <div className="section">
      <h1>Privacy Policies</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header">
            <AddEdit fetch={fetch} />
          </div>
          <div className="table-container">
            <table>
              <thead>
                <th>#</th>
                <th>Title</th>
                <th>Caption</th>
                <th>Active/Inactive</th>
                <th>Created at</th>
                <th>Actions</th>
              </thead>
              <tbody>
                {data?.privacyPolicies?.map((item, index) => (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.caption}</td>
                    <td>{item.isActive ? "Active" : "Inactive"}</td>
                    <td>{new Date(item.createdAt).toLocaleString()}</td>
                    <td>
                      <div className="table-actions">
                        {item?.isActive && (
                          <>
                            <AddEdit
                              type={`TnE-${item.id}`}
                              data={item}
                              fetch={fetch}
                            />
                            {/* {!item.isLive && (
                              <>
                                <button
                                  className="success"
                                  onClick={() =>
                                    makeTipAndTrickLiveHandler(item.id)
                                  }
                                >
                                  Publish
                                </button>
                              </>
                            )} */}
                          </>
                        )}
                        <button
                          className={item.isActive ? "danger" : "success"}
                          onClick={() =>
                            activateDeactivatePrivacyPolicyHandler(
                              item.id,
                              item.isActive
                            )
                          }
                        >
                          {item.isActive ? "Deactivate" : "Activate"}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination pageInfo={data.pageInfo} />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PrivacyPolicies);
