import React, { useEffect, useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectField from "../../components/Select/Select";
import actions from "../../actions";
import {
  updateAdminDetails,
  uploadSingleDocument,
  updateAdminImage,
  getAdminDetails,
} from "../../apis";
import { toast } from "react-toastify";
import "./accountSettings.css";

const AccountSettings = ({
  adminDetails,
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
  userDetailsRequest,
}) => {
  const [data, setData] = useState({
    firstName: adminDetails?.firstName || "",
    lastName: adminDetails?.lastName || "",
    gender: { value: adminDetails?.gender, label: adminDetails?.gender } || {
      value: "",
      label: "",
    },
    imageFile: null, // New state for image file
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    gender: "",
  });

  const selectDropdownChangeHandler = useCallback(
    (name, value) => {
      setData({
        ...data,
        [name]: value,
      });
    },
    [data]
  );

  const changeHandler = useCallback(
    (event) => {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    },
    [data]
  );

  const imageChangeHandler = (event) => {
    if (event?.target?.files[0]) {
      setData((prevState) => ({
        ...prevState,
        imageFile: event.target.files[0],
      }));
    }
  };

  useEffect(() => {
    setData({
      firstName: adminDetails?.firstName || "",
      lastName: adminDetails?.lastName || "",
      gender:
        { value: adminDetails?.gender, label: adminDetails?.gender } || "",
    });
  }, [adminDetails]);

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let tempErrors = {};
      const cleanData = {
        firstName: data?.firstName?.trim(),
        lastName: data?.lastName?.trim(),
        gender: data?.gender?.value,
      };

      if (!cleanData.firstName) tempErrors.firstName = "First name is required";
      if (!cleanData.lastName) tempErrors.lastName = "Last name is required";
      if (!cleanData.gender) tempErrors.gender = "Gender is required";

      if (Object.keys(tempErrors).length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      }

      // Reset errors if validation passes
      setErrors({});

      // Handle image upload if a new file is selected
      let imageDocId = adminDetails?.doc?._id;
      if (data.imageFile) {
        const form = new FormData();
        form.append("file", data.imageFile);
        const imageResponse = await uploadSingleDocument(form);
        imageDocId = imageResponse?.data?.data?.documentId;
        await updateAdminImage({ docId: imageDocId });
      }

      // Update admin details
      await updateAdminDetails({
        ...cleanData,
        docId: imageDocId,
      });

      // Fetch updated admin details and update global state
      const apiResponse = await getAdminDetails();
      if (apiResponse?.data?.data?.adminDetails) {
        userDetailsRequest(apiResponse?.data?.data?.adminDetails);
      }

      stopLoadingRequest();
      toast.success("Profile updated successfully");
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message || "An error occurred");
      if (error?.response?.status === 401) logoutRequest();
    }
  }, [data, adminDetails, startLoadingRequest, stopLoadingRequest, userDetailsRequest, logoutRequest]);

  return (
    <div className="section">
      <div className="settings-wrapper">
        <div className="wrapper image">
          <input
            onChange={imageChangeHandler}
            accept="Image/*"
            id="image"
            type="file"
          />
          <label htmlFor="image">
            <div>
            <img
        src={
          data.imageFile
            ? URL.createObjectURL(data.imageFile) // Display selected image
            : adminDetails?.doc?.url || "/images/dummyPerson.jpg" // Fallback options
        }
        alt="Profile"
      />
            </div>
          </label>
        </div>
        <div className="wrapper inputs">
          <div className="input-wrapper">
            <label>First Name</label>
            <input
              onChange={changeHandler}
              name="firstName"
              value={data.firstName}
            />
            {errors.firstName && <p>{errors.firstName}</p>}
          </div>
          <div className="input-wrapper">
            <label>Last Name</label>
            <input
              onChange={changeHandler}
              name="lastName"
              value={data.lastName}
            />
            {errors.lastName && <p>{errors.lastName}</p>}
          </div>
          <div className="input-wrapper">
            <label>Gender</label>
            <SelectField
              name="gender"
              onChange={selectDropdownChangeHandler}
              value={data.gender}
              isMulti={false}
              options={[
                { value: "Male", label: "Male" },
                { value: "Female", label: "Female" },
                { value: "Not Specified", label: "Not Specified" },
              ]}
            />
            {errors.gender && <p>{errors.gender}</p>}
          </div>
          <button onClick={submitHandler} className="primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  adminDetails: state.auth.data,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
      userDetailsRequest: actions.auth.userDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
