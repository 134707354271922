import { FaUsers, FaQuestion, FaFileAlt } from "react-icons/fa/index.esm";
import { PiFlagBannerFill } from "react-icons/pi/index.esm";
import { FaCalendarAlt } from "react-icons/fa/index.esm";
import {
  MdPrivacyTip,
  MdTipsAndUpdates,
  MdAnalytics,
} from "react-icons/md/index.esm";
import { TbInfoTriangleFilled } from "react-icons/tb/index.esm";
import { RiCoupon3Fill, RiAdminFill } from "react-icons/ri/index.esm";
import { GiShoppingBag, GiShop, GiSaloon } from "react-icons/gi/index.esm";
import { IoNewspaperSharp } from "react-icons/io5/index.esm";
import { TbCategoryFilled } from "react-icons/tb/index.esm";
import Login from "../../pages/Auth/Login";
import Admins from "../../pages/Admins";
import Banners from "../../pages/Banners";
import Appointments from "../../pages/Appointments/Appointments";
import PrivacyPolicies from "../../pages/PrivacyPolicies";
import TermsAndConditions from "../../pages/TermsAndConditions";
import TipsAndTricks from "../../pages/TipsAndTricks";
import BlogCategories from "../../pages/BlogCategories";
import Blogs from "../../pages/Blogs";
import Orders from "../../pages/Orders";
import Users from "../../pages/Users";
import ProductPromocodes from "../../pages/ProductPromocodes";
import AccountSettings from "../../pages/AccountSettings";
import ChangePassword from "../../pages/ChangePassword";
import ProductCategories from "../../pages/ProductCategories";
import Dashboard from "../../pages/Dashboard";
import Images from "../../pages/Salons";
import Faqs from "../../pages/Faqs";
import Reports from "../../pages/Reports";

export default [
  {
    path: "/login",
    component: <Login />,
    isAuthenticated: false,
  },
  {
    path: "/settings",
    component: <AccountSettings />,
    isAuthenticated: true,
  },
  {
    path: "/password",
    component: <ChangePassword />,
    isAuthenticated: true,
  },
  {
    link: "/dashboard?requestType=Today",
    icon: <MdAnalytics size={18} />,
    path: "/dashboard",
    component: <Dashboard />,
    isAuthenticated: true,
    label: "Dashboard",
    permission: "dashboard",
  },
  {
    link: "/users?page=1",
    icon: <FaUsers size={18} />,
    path: "/users",
    component: <Users />,
    isAuthenticated: true,
    label: "User Management",
    permission: "user_management",
  },
  // {
  //   link: "/salons/images",
  //   icon: <GiSaloon size={18} />,
  //   path: "/salons/images",
  //   component: <Images />,
  //   isAuthenticated: true,
  //   label: "Salons",
  // },
  {
    link: "/appointments?page=1&requestType=Completed&search=",
    icon: <FaCalendarAlt size={14} />,
    path: "/appointments",
    component: <Appointments />,
    isAuthenticated: true,
    label: "Booking List",
    permission: "booking_list",
  },
  {
    link: "/orders?page=1&requestType=All&search=",
    icon: <GiShoppingBag size={14} />,
    path: "/orders",
    component: <Orders />,
    isAuthenticated: true,
    label: "Order List",
    permission: "order_list",
  },
  {
    link: "/product-promocodes?page=1",
    icon: <RiCoupon3Fill size={14} />,
    path: "/product-promocodes",
    component: <ProductPromocodes />,
    isAuthenticated: true,
    label: "Product Promocodes",
    permission: "product_promocodes",
  },
  {
    link: "/admins?page=1",
    icon: <RiAdminFill size={18} />,
    path: "/admins",
    component: <Admins />,
    isAuthenticated: true,
    label: "Admins",
    permission: "admins",
  },
  {
    link: "/blog-categories?page=1",
    icon: <TbCategoryFilled size={14} />,
    path: "/blog-categories",
    component: <BlogCategories />,
    isAuthenticated: true,
    label: "Blog Categories",
    permission: "blogs",
  },
  {
    link: "/blogs?page=1",
    icon: <IoNewspaperSharp size={14} />,
    path: "/blogs",
    component: <Blogs />,
    isAuthenticated: true,
    label: "Blogs",
    permission: "blogs",
  },
  {
    link: "/product-categories?page=1",
    icon: <GiShop size={14} />,
    path: "/product-categories",
    component: <ProductCategories />,
    isAuthenticated: true,
    label: "Product Categories",
    permission: "product_categories",
  },
  {
    link: "/tips-tricks?page=1",
    icon: <MdTipsAndUpdates size={14} />,
    path: "/tips-tricks",
    component: <TipsAndTricks />,
    isAuthenticated: true,
    label: "Tips And Tricks",
    permission: "tips_tricks",
  },
  {
    link: "/banners?page=1",
    icon: <PiFlagBannerFill size={18} />,
    path: "/banners",
    component: <Banners />,
    isAuthenticated: true,
    label: "Banners",
    permission: "banners",
  },
  {
    link: "/privacy-policies?page=1",
    icon: <MdPrivacyTip size={14} />,
    path: "/privacy-policies",
    component: <PrivacyPolicies />,
    isAuthenticated: true,
    label: "Privacy Policies",
    permission: "privacy_policies",
  },
  {
    link: "/terms-conditions?page=1",
    icon: <TbInfoTriangleFilled size={14} />,
    path: "/terms-conditions",
    component: <TermsAndConditions />,
    isAuthenticated: true,
    label: "Terms and Conditions",
    permission: "terms_conditions",
  },
  {
    link: "/faqs?page=1",
    icon: <FaQuestion size={14} />,
    path: "/faqs",
    component: <Faqs />,
    isAuthenticated: true,
    label: "FAQ",
    permission: "faqs",
  },
  {
    link: "/reports",
    icon: <FaFileAlt size={14} />,
    path: "/reports",
    component: <Reports />,
    isAuthenticated: true,
    label: "Reports",
    permission: "report",
  },
];
