import axios from "axios";

export default (endpoint, method, data, isMultipart) =>
  axios({
    url: `${process.env.REACT_APP_API_BASE_URL}${endpoint}`,
    method,
    data,
    headers: {
      "Content-Type": isMultipart ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${localStorage.getItem("authorization")}`,
    },
  });
