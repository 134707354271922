import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaAngleLeft,
  FaAnglesLeft,
  FaAnglesRight,
  FaAngleRight,
} from "react-icons/fa6/index.esm";

const Pagination = ({ pageInfo }) => {
  // console.log(pageInfo);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [pages, setPages] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  useEffect(() => {
    if (pageInfo?.page) {
      pageshandler();
    }
  }, [pageInfo]);
  const pageshandler = useCallback(() => {
    const temp = [];
    const tempLastPage =
      pageInfo?.total % 20 > 0
        ? parseInt(pageInfo?.total / 20) + 1
        : parseInt(pageInfo?.total);
    setLastPage(tempLastPage);
    if (tempLastPage <= 5) {
      for (let i = 1; i <= tempLastPage; i++) {
        temp.push(i);
      }
    } else if (tempLastPage - pageInfo.page <= 2) {
      console.log("hit");
      for (let i = tempLastPage - 4; i <= tempLastPage; i++) {
        temp.push(i);
      }
    } else if (pageInfo.page - 1 <= 2) {
      for (let i = 1; i <= 5; i++) {
        temp.push(i);
      }
    } else {
      for (let i = pageInfo.page - 2; i <= pageInfo.page + 2; i++) {
        temp.push(i);
      }
    }
    setPages(temp);
  }, [pageInfo]);

  const navigationHandler = useCallback(
    (page) => {
      let tempSearch =
        search
          ?.split("?")[1]
          ?.split("&")
          ?.map((el) => {
            if (el.includes("page")) {
              return `page=${page}`;
            } else {
              return el;
            }
          })
          ?.join("&") || "";
      if (tempSearch) {
        navigate(pathname + "?" + tempSearch);
      }
    },
    [pathname, search]
  );
  return (
    <div className="pagination">
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() => (pageInfo?.page !== 1 ? navigationHandler(1) : null)}
      >
        <FaAnglesLeft size={8} />
      </button>
      <button
        className={pageInfo?.page !== 1 ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== 1 ? navigationHandler(pageInfo.page - 1) : null
        }
      >
        <FaAngleLeft size={8} />
      </button>
      <div className="pages">
        {pages?.map((el) => (
          <button
            key={`${pathname}-${el}`}
            className={pageInfo?.page === el ? "number active" : "number"}
            onClick={() =>
              pageInfo?.page !== el ? navigationHandler(el) : null
            }
          >
            <span>{el}</span>
          </button>
        ))}
      </div>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== lastPage
            ? navigationHandler(pageInfo.page + 1)
            : null
        }
      >
        <FaAngleRight size={8} />
      </button>
      <button
        className={pageInfo?.page !== lastPage ? "active" : ""}
        onClick={() =>
          pageInfo?.page !== lastPage ? navigationHandler(lastPage) : null
        }
      >
        <FaAnglesRight size={8} />
      </button>
      <p>
  Showing{" "}
  {pageInfo?.page
    ? (pageInfo.page - 1) * 20 + 1
    : 0}{" "}
  to{" "}
  {pageInfo?.page && pageInfo.total
    ? Math.min(pageInfo.page * 20, pageInfo.total)
    : 0}{" "}
  of {pageInfo?.total ?? 0}
</p>

    </div>
  );
};

export default Pagination;
