import axios from "../utils/axios";

//AUTH
export const login = (data) => axios("auth/login", "POST", data);
export const getAdminDetails = () => axios("auth/profile", "GET");
export const updateAdminDetails = (data) => axios("auth/profile", "PUT", data);
export const updateAdminImage = (data) =>
  axios("auth/profile/image", "PATCH", data);
export const updateAdminPassword = (data) =>
  axios("auth/profile/change-password", "PATCH", data);

//USERS
export const getUsers = (search) => axios(`users${search}`, "GET");
// export const getUsersReport = (reportType,days,mode) => axios(`${reportType}?lastIndays=${days}&page=1&mode=view`, "GET");
export const getUsersReport = (reportType, days, mode,page) =>
  axios(`${reportType}?lastIndays=${days}&page=${page}&mode=${mode}`, "GET");

export const activateDeactivateUser = (id, data) =>
  axios(`users/${id}`, "PATCH", data);

// DOCUMENTS
export const uploadSingleDocument = (file) =>
  axios("documents/single", "POST", file, true);

// SALONS
export const getSalons = () => axios("salons", "GET");
export const updateSalon = (id, data) =>
  axios(`salons/${id}/images`, "PATCH", data);

//ADMINS
export const getAdmins = (search) => axios(`admins${search}`, "GET");
export const getPermissions = () => axios("admins/permissions", "GET");
export const createAdmin = (data) => axios("admins", "POST", data);
export const updateAdmin = (id, data) => axios(`admins/${id}`, "PUT", data);
export const activateDeactivateAdmin = (id, data) =>
  axios(`admins/${id}`, "PATCH", data);
export const changePassword = (id, data) =>
  axios(`admins/${id}/password`, "PATCH", data);

//PRODUCT PROMOCODES
export const getProductPromocodes = (search) =>
  axios(`product-promocodes${search}`, "GET");
export const createProductPromocode = (data) =>
  axios("product-promocodes", "POST", data);
export const updateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PUT", data);
export const activateDeactivateProductPromocode = (id, data) =>
  axios(`product-promocodes/${id}`, "PATCH", data);

//BANNERS
export const getBanners = (query) => axios(`banners${query}`, "GET");
export const createBanner = (data) => axios("banners", "POST", data);
export const updateBanner = (id, data) => axios(`banners/${id}`, "PUT", data);
export const makeBannerLive = (id) => axios(`banners/${id}/live`, "PATCH");
export const deleteBanner = (id) => axios(`banners/${id}`, "DELETE");

//FAQS
export const getFaqs = (query) => axios(`faqs${query}`, "GET");
export const createFaq = (data) => axios("faqs", "POST", data);
export const updateFaq = (id, data) => axios(`faqs/${id}`, "PUT", data);
export const activateDeactivateFaq = (id, data) =>
  axios(`faqs/${id}`, "PATCH", data);

//PRIVACY POLICIES
export const getPrivacyPolicies = (query) =>
  axios(`privacy-policies${query}`, "GET");
export const createPrivacyPolicy = (data) =>
  axios("privacy-policies", "POST", data);
export const updatePrivacyPolicy = (id, data) =>
  axios(`privacy-policies/${id}`, "PUT", data);
export const makePrivacyPolicyLive = (id) =>
  axios(`privacy-policies/${id}/live`, "PATCH");
export const deletePrivacyPolicy = (id) =>
  axios(`privacy-policies/${id}`, "DELETE");

//TERMS AND CONDITIONS
export const getTermsAndConditions = (query) =>
  axios(`terms-conditions${query}`, "GET");
export const createTermAndCondition = (data) =>
  axios("terms-conditions", "POST", data);
export const updateTermAndCondition = (id, data) =>
  axios(`terms-conditions/${id}`, "PUT", data);
export const makeTermAndConditionLive = (id) =>
  axios(`terms-conditions/${id}/live`, "PATCH");
export const deleteTermAndCondition = (id) =>
  axios(`terms-conditions/${id}`, "DELETE");

//TIPS AND TRICKS
export const getTipsAndTricks = (query) => axios(`tips-tricks${query}`, "GET");
export const createTipAndTrick = (data) => axios("tips-tricks", "POST", data);
export const updateTipAndTrick = (id, data) =>
  axios(`tips-tricks/${id}`, "PUT", data);
export const makeTipAndTrickLive = (id) =>
  axios(`tips-tricks/${id}/live`, "PATCH");
export const deleteTipAndTrick = (id) => axios(`tips-tricks/${id}`, "DELETE");

// SALONS
export const getAppointments = (search) =>
  axios(`salons/appointments${search}`, "GET");

// ORDERS
export const getOrders = (search) => axios(`orders${search}`, "GET");

//DASHBOARD
export const getDashboardData = (search) => axios(`dashboard${search}`, "GET");

//BLOG CATEGORIES
export const getBlogCategories = (search) =>
  axios(`blogs/categories${search}`, "GET");
export const getActiveBlogCategories = () =>
  axios(`blogs/categories/active`, "GET");
export const createBlogCategory = (data) =>
  axios("blogs/categories", "POST", data);
export const updateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PUT", data);
export const activateDeactivateBlogCategory = (id, data) =>
  axios(`blogs/categories/${id}`, "PATCH", data);

//PRODUCT CATEGORIES
export const getCollections = () => axios(`products/collections`, "GET");
export const getProductCategories = (search) =>
  axios(`products/categories${search}`, "GET");
export const getActiveProductCategories = () =>
  axios(`products/categories/active`, "GET");
export const createProductCategory = (data) =>
  axios("products/categories", "POST", data);
export const updateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PUT", data);
export const activateDeactivateProductCategory = (id, data) =>
  axios(`products/categories/${id}`, "PATCH", data);

//BLOGS
export const getBlogs = (query) => axios(`blogs${query}`, "GET");
export const createBlog = (data) => axios("blogs", "POST", data);
export const updateBlog = (id, data) => axios(`blogs/${id}`, "PUT", data);
export const markBlogAsReviewed = (id) =>
  axios(`blogs/${id}/reviewed`, "PATCH");
export const markBlogAsPublished = (id) =>
  axios(`blogs/${id}/publish`, "PATCH");
export const deleteBlog = (id) => axios(`blogs/${id}`, "DELETE");
