import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { getUsersReport } from "../../apis";
import * as XLSX from "xlsx"; // Import the xlsx library
import { type } from "@testing-library/user-event/dist/type";
import { update } from "lodash";
import Pagination from "../../components/Pagination/Pagination";

const Reports = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [reportType, setReportType] = useState("users-report");
  const [days, setDays] = useState("7");
  const [mode, setMode] = useState("view");
  const [data, setData] = useState([]);
  const [pageInfo, setPageInfo] = useState([]);
  const [headers, setHeaders] = useState([]);

  // Helper function to flatten nested objects
  const flattenObject = (obj, prefix = "") => {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(result, flattenObject(obj[key], newKey)); // Recursively flatten the nested object
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  };

  const mapHeaders = (reportType) => {
    const headersMap = {
      "users-report": {
        firstName: "First Name",
        lastName: "Last Name",
        gender: "Gender",
        "baseSalon.name": "Base Salon Name", // Adjust for nested object
        isConsentAllowed: "Consent Allowed",
        referredBy: "Referred By",
        referralLevel: "Referral Level",
      },
      "banners-report": {
        id: "ID",
        caption: "Caption",
        title: "Title",
        createdAt: "Upload Date",
        goLiveDateTime: "Go Live Date & Time",
        isLive: "Is Live",
        "action.dateTime": "Last Action Date & Time",
        type: "Type",
      },
      "blogs-report": {
        id: "ID",
        headline: "Headline",
        content: "Content",
        createdAt: "Upload Date",
        "category.name": "Category Name",
        "document.url": " Blog Image Url",
        readingTime: "Reading Time",
        isActive: "Is Active",
        publishDateTime: "Publish Date & Time",
        status: "Status",
        updatedAt: "Updated Date",
      },
      "privacy-policies-report": {
        id: "ID",
        title: "Title",
        caption: "Caption",
        createdAt: "Upload Date",
        goLiveDateTime: "Go Live Date & Time",
        isActive: "Is Active",
        updatedAt: "Last Action Date & Time",
        "action.dateTime": "Action Time",
      },
      "notifications-report": {
        id: "Notification ID",
        title: "Title",
        message: "Message",
        createdAt: "Created At",
        updatedAt: "Updated At",
      },
      "socialPosts-report": {
        id: "Notification ID",
        url: "Image Location",
        caption: "Caption",
        createdAt: "Created At",
        updatedAt: "Updated At",
      },
      "salonHomePages-report": {
        id: "Salon ID",
        name: "Name",
        description: "Caption",
        images: "Images",
        createdAt: "Created At",
        updatedAt: "Updated At",
      },
    };

    return headersMap[reportType] || {}; // Return headers as an object for the given report type
  };

  const renderRowData = (item, reportType) => {
    switch (reportType) {
      case "users-report":
        return (
          <>
            <td>{item?.firstName}</td>
            <td>{item?.lastName}</td>
            <td>{item?.gender}</td>
            <td>{item?.baseSalon?.name}</td>
            <td>{item?.isConsentAllowed ? "Yes" : "No"}</td>
            <td>{item?.referredBy}</td>
            <td>{item?.referralLevel}</td>
          </>
        );
      case "banners-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>{item?.caption}</td>
            <td>{item?.title}</td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.goLiveDateTime).toLocaleString()}</td>
            <td>{item?.isLive ? "Yes" : "No"}</td>
            <td>{item?.action?.dateTime}</td>
            <td>{item?.type}</td>
          </>
        );
      case "blogs-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>{item?.headline}</td>
            <td>{item?.content}</td>
            <td>{item?.createdAt}</td>
            <td>{item?.category?.name}</td>
            <td>{item?.document?.url}</td>
            <td>{item?.readingTime}</td>
            <td>{item?.isActive ? "Active" : "Inactive"}</td>
            <td>{item?.publishDateTime}</td>
            <td>{item?.status}</td>
            <td>{new Date(item.createdAt).toLocaleString()}</td>
          </>
        );
      case "privacy-policies-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>{item?.title}</td>
            <td>{item?.caption}</td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.goLiveDateTime).toLocaleString()}</td>
            <td>{item?.isActive ? "Active" : "Inactive"}</td>
            <td>{item?.updatedAt}</td>
            <td>{item?.action?.dateTime}</td>
          </>
        );
      case "notifications-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>{item?.title}</td>
            <td>{item?.message}</td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.updatedAt).toLocaleString()}</td>
          </>
        );
      case "socialPosts-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>
              {item?.url && <img src={item?.url} alt="Social Post Image" />}
            </td>
            <td>{item?.caption}</td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.updatedAt).toLocaleString()}</td>
          </>
        );
      case "salonHomePages-report":
        return (
          <>
            <td>{item?.id}</td>
            <td>{item?.name}</td>
            <td>
              {item?.description}
            </td>
            <td>{item?.images}</td>
            <td>{new Date(item?.createdAt).toLocaleString()}</td>
            <td>{new Date(item?.updatedAt).toLocaleString()}</td>
          </>
        );
      default:
        return null;
    }
  };

  const handleDownload = async () => {
    // Display the toast loader
    const toastId = toast.loading("Preparing your report, please wait...");

    try {
      const response = await getUsersReport(reportType, days, "download", 1);
      console.log(response);
      let data = [];

      if (reportType === "users-report") {
        data = response.data.data.users;
      } else if (reportType === "banners-report") {
        data = response.data.data.banners;
      } else if (reportType === "blogs-report") {
        data = response.data.data.blogs;
      } else if (reportType === "privacy-policies-report") {
        data = response.data.data.privacyPolicies;
      } else if (reportType === "notifications-report") {
        data = response.data.data.notifications;
      } else if (reportType === "socialPosts-report") {
        data = response.data.data.socialPosts;
      }else if (reportType === "salonHomePages-report") {
        data = response.data.data.salons;
      }
       else {
        console.error(`Unsupported report type: ${reportType}`);
      }

      // Check if the data array is empty
      if (!data || data.length === 0) {
        toast.update(toastId, {
          render: `No data available for ${reportType} report of last ${days} days.`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      }

      // Dynamically map headers based on report type
      const headers = mapHeaders(reportType);

      // Flatten each data row
      const flattenedData = data.map((item) => flattenObject(item));

      // Get all keys from the flattened data
      const allKeys = new Set();
      flattenedData.forEach((item) => {
        Object.keys(item).forEach((key) => allKeys.add(key));
      });

      // Combine headers and backend keys to form the final list of headers
      const finalHeaders = Array.from(allKeys).map((key) => {
        return {
          key: key,
          label: headers[key] || key, // Use label from mapHeaders or fallback to the key
        };
      });

      // Map flattened data to match the headers
      const mappedData = flattenedData.map((item) =>
        finalHeaders.reduce((acc, header) => {
          acc[header.label] = item[header.key];
          return acc;
        }, {})
      );

      console.log(mappedData);

      // Create a worksheet from the mapped data
      const ws = XLSX.utils.json_to_sheet(mappedData);

      // Set dynamic column widths
      const colWidths = finalHeaders.map((header) => {
        // Find the max length of the header or the column data
        const maxLength = Math.max(
          header.label.length,
          ...mappedData.map((row) => String(row[header.label]).length)
        );
        return { wpx: maxLength * 10 }; // Width based on content, multiplied by 10 for spacing
      });

      // Apply the dynamic column widths
      ws["!cols"] = colWidths;

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        ws,
        `${reportType.replace("-", " ").toUpperCase()} Report`
      );

      // Trigger the download
      XLSX.writeFile(wb, `${reportType}_${days}_days.xlsx`);

      // Update the toast to success
      toast.update(toastId, {
        render: `Download completed for ${reportType} report of last ${days} days!`,
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      // Update the toast to error
      toast.update(toastId, {
        render: "Error while downloading the report.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      console.error("Error downloading the report:", error);
    }
  };

  useEffect(() => {
    const fetchReport = async () => {
      try {
        console.log("Fetching report...");
        const response = await getUsersReport(reportType, days, "view", 1);
        setPageInfo(response.data?.data?.pageInfo);
        console.log(response); // Handle the response as needed
  
        if (reportType === "users-report") {
          setData(response.data.data.users);
        } else if (reportType === "banners-report") {
          setData(response.data.data.banners);
        } else if (reportType === "blogs-report") {
          setData(response.data.data.blogs);
        } else if (reportType === "privacy-policies-report") {
          setData(response.data.data.privacyPolicies);
        } else if (reportType === "notifications-report") {
          setData(response.data.data.notifications);
        } else if (reportType === "socialPosts-report") {
          setData(response.data.data.socialPosts);
        } else if (reportType === "salonHomePages-report") {
          setData(response.data.data.salons);
        } else {
          console.error(`Unsupported report type: ${reportType}`);
        }
      } catch (error) {
        console.error("Error fetching report:", error);
      }
    };
  
    fetchReport();
  }, [mode, reportType, days]);
   // Add dependencies as needed

  useEffect(() => {
    const newHeaders = mapHeaders(reportType);
    setHeaders(newHeaders);
  }, [reportType]);
  console.log("data", data);
  console.log(data);
  return (
    <div className="section">
      <h1>Reports</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header flex-start">
            {/* Mode Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="mode">Mode:</label>
              <select
                id="mode"
                value={mode}
                onChange={(e) => setMode(e.target.value)}
              >
                <option value="view">View</option>
                <option value="download">Download</option>
              </select>
            </div>

            {/* Report Type Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="reportType">Report Type:</label>
              <select
                id="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option value="users-report">User</option>
                <option value="banners-report">Banner</option>
                <option value="blogs-report">Blogs</option>
                <option value="privacy-policies-report">Privacy Policy</option>
                <option value="notifications-report">Notification</option>
                <option value="socialPosts-report">Social Post</option>
                <option value="salonHomePages-report">Salon HomePage</option>
              </select>
            </div>

            <div className="input-wrapper">
              <label htmlFor="days">Time Period:</label>
              <select
                id="days"
                value={days}
                onChange={(e) => setDays(e.target.value)}
              >
                <option value="0">Today</option>
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
                <option value="365">Last 1 year</option>
              </select>
            </div>
            {/* Conditional Time Period Dropdown and Download Button */}
            {mode === "download" && (
              <>
                <button
                  onClick={handleDownload}
                  className="btn-download"
                  style={{
                    backgroundColor: "#4CAF50", // Green background
                    color: "#fff", // White text
                    padding: "10px 20px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                >
                  Download
                </button>
              </>
            )}
            {mode === "view" && (
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      {Object.values(headers).map((header, index) => (
                        <th key={index}>{header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        {renderRowData(item, reportType)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            {mode === "view" && <Pagination pageInfo={pageInfo} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Reports);
