import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Routes from "./components/routes/Routes";
import Loader from "./components/Loader";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./global.css";
import "./toast.css";

function App() {
  return (
    <Provider store={store}>
      <ToastContainer  position="top-center" theme="dark"/>
      <BrowserRouter>
        <Loader />
        <Routes />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
