import React, { useState, useCallback } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { updateAdminPassword } from "../../apis";
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa/index.esm";
import "./changePassword.css";

const ChangePassword = ({
  logoutRequest,
  startLoadingRequest,
  stopLoadingRequest,
}) => {
  const [data, setData] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const changeHandler = useCallback(
    (event) => {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    },
    [data]
  );

  const submitHandler = useCallback(async () => {
    try {
      startLoadingRequest();
      let cleanData = {
        oldPassword: data?.oldPassword?.trim(),
        newPassword: data?.newPassword?.trim(),
        confirmPassword: data?.confirmPassword?.trim(),
      };
      let tempErrors = {};
  
      // Check if any of the password fields are empty
      if (!cleanData.oldPassword) {
        tempErrors["oldPassword"] = "Old password is required";
      } else if (cleanData.oldPassword.length < 8) {
        tempErrors["oldPassword"] = "Old password must have at least 8 characters";
      }
  
      if (!cleanData.newPassword) {
        tempErrors["newPassword"] = "New password is required";
      } else if (cleanData.newPassword.length < 8) {
        tempErrors["newPassword"] = "New password must have at least 8 characters";
      }
  
      if (!cleanData.confirmPassword) {
        tempErrors["confirmPassword"] = "Confirm password is required";
      } else if (cleanData.confirmPassword.length < 8) {
        tempErrors["confirmPassword"] = "Confirm password must have at least 8 characters";
      }
  
      // Check if new password and confirm password match
      if (cleanData.newPassword !== cleanData.confirmPassword) {
        tempErrors["confirmPassword"] = "Confirm password and new password must be the same";
      }
  
      // If there are errors, set them and stop the request
      if (Object.keys(tempErrors).length > 0) {
        setErrors(tempErrors);
        stopLoadingRequest();
        return;
      } else {
        setErrors({});
      }
  
      // Proceed to update the password
      await updateAdminPassword(cleanData);
      setData({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setErrors({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      stopLoadingRequest();
      toast.success("Password updated successfully");
    } catch (error) {
      stopLoadingRequest();
      toast.error(error?.response?.data?.message);
      if (error?.response?.status === 401) {
        logoutRequest();
      }
    }
  }, [data]);
  

  return (
    <div className="section">
      <div className="settings-wrapper">
        <div className="wrapper inputs">
          <div className="input-wrapper">
            <label>Old Password</label>
            <input
              onChange={changeHandler}
              name="oldPassword"
              type={showOldPassword ? "text" : "password"}
              value={data.oldPassword}
            />
            <div
              onClick={() => setShowOldPassword(!showOldPassword)}
              className={showOldPassword ? "icon active" : "icon"}
            >
              <FaEye size={16} />
            </div>
            {errors.oldPassword && <p>{errors.oldPassword}</p>}
          </div>
          <div className="input-wrapper">
            <label>New Password</label>
            <input
              onChange={changeHandler}
              name="newPassword"
              type={showNewPassword ? "text" : "password"}
              value={data.newPassword}
            />
            <div
              onClick={() => setShowNewPassword(!showNewPassword)}
              className={showNewPassword ? "icon active" : "icon"}
            >
              <FaEye size={16} />
            </div>
            {errors.newPassword && <p>{errors.newPassword}</p>}
          </div>
          <div className="input-wrapper">
            <label>Confirm Password</label>
            <input
              onChange={changeHandler}
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={data.confirmPassword}
            />
            <div
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className={showConfirmPassword ? "icon active" : "icon"}
            >
              <FaEye size={16} />
            </div>
            {errors.confirmPassword && <p>{errors.confirmPassword}</p>}
          </div>
          <button onClick={submitHandler} className="primary">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      startLoadingRequest: actions.loader.startLoading,
      stopLoadingRequest: actions.loader.stopLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ChangePassword);
